<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar"
  [class.adminSidebar]="navServices.adminMenuCollapsed">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-9 col-md-auto p-0">
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
      <div class="logo-wrapper" style="display: block !important">
        <a routerLink="/" class="d-flex align-items-center">
          <span><img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" /></span><h6 class="mb-0 ms-2" style="font-weight: 700;">શ્રી વાગડ વિસા ઓસવાલ ચોવીસી મહાજન</h6>
          <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
        </a>
      </div>
    </div>
    <!-- <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <app-swiper></app-swiper>
    </div> -->

    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-3 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <!-- <li>
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-bookmark></app-bookmark>
        </li> -->
        <!-- <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li> -->
        <!-- <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li> -->
        <li class="d-flex d-md-none"><a href="/user/my-profile"><app-feather-icons [icon]="'user'"></app-feather-icons></a></li>
        <li (click)="logoutFunc()" class="d-flex d-md-none"><app-feather-icons [icon]="'log-out'"></app-feather-icons></li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->