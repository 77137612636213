import { Time } from "@angular/common";
import { Member } from "./member.model";
import { ContactDetail } from "./emergencyContact.model";
import { Comment } from "../../shared/model/comment.model";
import { User } from "./user.model";
export class Post {
  postId: number; // ok
  postNo: string; // ok
  postTypeId: number; // ok
  memberId: string; // ok
  // title: string; // ok
  // description: string; // ok
  // bannerImageUrl?: string; // ok
  status?: number; // ok
  isActive?: boolean; // ok
  addedBy: number; // ok
  addedDate: string; // ok
  editedBy: number; // ok
  editedDate: string; // ok
  // post_maran_detail?: MaranDetail;
  // family_members?: FamilyMember[];
  // contact_persons?: ContactPersonDetail[];
  // post_type_detail?: PostTypeDetail;
  // member_detail: MemberDetail;
  post_detail?: PostDetail[];
  reviseReason?: string
  reviseDate?: Date
  reviseBy?: User
  cancelReason?: string
  cancelledDate?: Date
  cancelledBy?: User

  //no needed
  // postBy: string;
  // postDate: Date;
  additionalNote?: string;
  likes_count?: number;
  commentsCount?: number;
  likedBy?: LikedBy[];
  comments?: Comment[];
  reason?: string | null;
  funeralDateTime?: Date;
  startDateTime?: Date;
  endDateTime?: Date;
  startAddress?: string;
  endAddress?: string;
  prayerStartTime?: Date;
  prayerEndTime?: Date;
  address?: String;
  // member_detail?: Member;
  ////////////////////////////////////////

  constructor(
    postId: number,
    postNo: string,
    memberId: string,
    addedBy: number,
    addedDate: string,
    editedBy: number,
    editedDate: string,
    // description: string,
    postTypeId: number,
    // title?: string,
    additionalNote?: string,
    likesCount?: number,
    commentsCount?: number,
    more_images?: moreImage[],
    likedBy?: LikedBy[],
    comments?: Comment[],
    isActive?: boolean,
    // bannerImageUrl?: string,
    status?: number,
    cancelReason?: string | null,
    cancelledDate?: Date | null,
    // family_members?: FamilyMember[],
    // contact_persons?: ContactPersonDetail[],
    funeralDateTime?: Date,
    startDateTime?: Date,
    endDateTime?: Date,
    startAddress?: string,
    endAddress?: string,
    prayerStartTime?: Date,
    prayerEndTime?: Date,
    address?: String
  ) {
    this.postId = postId;
    this.postNo = postNo;
    this.memberId = memberId;
    this.addedBy = addedBy;
    this.addedDate = addedDate;
    this.editedBy = editedBy;
    this.editedDate = editedDate;
    // this.description = description;
    this.postTypeId = postTypeId;
    // this.title = title;
    this.additionalNote = additionalNote;
    this.likes_count = likesCount;
    this.commentsCount = commentsCount;
    this.likedBy = likedBy;
    this.comments = comments;
    this.isActive = isActive;
    // this.bannerImageUrl = bannerImageUrl;
    // this.more_images = more_images;
    this.status = status;
    this.cancelReason = cancelReason;
    this.cancelledDate = cancelledDate;
    // this.family_members = family_members;
    // this.contact_persons = contact_persons;
    this.funeralDateTime = funeralDateTime;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.startAddress = startAddress;
    this.endAddress = endAddress;
    this.prayerStartTime = prayerStartTime;
    this.prayerEndTime = prayerEndTime;
    this.address = address;
  }
}
export interface PostDetail {
  postDetailId: number;
  postId: number;
  languageCode: string;
  title: any;
  description: any;
  bannerImageUrl: string;
  post_maran_detail: MaranDetail;
  family_members?: FamilyMember[];
  contact_persons?: ContactPersonDetail[];
  post_type_detail?: PostTypeDetail;
  member_detail: MemberDetail;
  more_images?: moreImage[];
}

export interface LikedBy {
  profileImg: string;
  name: string;
}

export interface FamilyMember {
  familyMemberId: number; // ok
  postDetailId: number; // ok
  memberName: string; // ok
  memberImageUrl: string; // ok
  relation: string; // ok
}

export interface ContactPersonDetail {
  contactPersonId: number;
  postDetailId: number;
  personName: string;
  contactNumber: number;
}

export interface moreImage {
  moreImageId: number
  postDetailId: number
  imageUrl: string
}

export interface PostTypeDetail {
  postTypeId: number;
  postTypeName: string;
}

export interface MaranDetail {
  maranDetailId: number
  postDetailId: number
  personName: string
  age: number
  village: string
  deathDateTime: string
  gender: string
  martialStatus: string
  deathReason: string
  fatherOrInLawName: string
  villageFatherOrInLaw: string
  hasPrathnaSabha: string
  funeralPrayerDate: string
  funeralPrayerStartTime: string
  funeralPrayerEndTime: any
  isSameAddress: number
  homeAddress: string
  startAddress: string
  endAddress: any
  additionalNote: any
}
export interface MemberDetail {
  memberId: number;
  fullName: string;
  profileImageUrl: string;
}
