import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'simpleDateFormate'
})
export class SimpleDatePipe implements PipeTransform {

    transform(value: any): any {
        if (!value) return value;
        return formatDate(new Date(value.replace(/\s+/g, 'T')), 'dd-MM-yyyy hh:mm a', 'en-US');
      }

}
