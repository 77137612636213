<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <!-- Beta - Logo -->
      <!-- <p class="mb-0">Powered by <span> <img src="/assets/images/logo/footer-logo.jpg" alt="communityplatform" width="25px" class="img-fluid rounded-2"></span> www.communityplatform.in | Copyright {{ today | date:'y'}} © bulu  </p> -->
      <!-- Live - Logo -->
      <p class="mb-0">Powered by <span> <img src="/assets/images/logo/small-logo-2.jpg" alt="communityplatform" width="25px" class="img-fluid rounded-2"></span> www.communityplatform.in | Copyright {{ today | date:'y'}} © bulu  </p>
    </div>
  </div>
</div>